import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import vuetify from './addons/vuetify.js'
import store from './addons/store.js'

const gyra_routes = require('./gyra/routes')
const iron_routes = require('./iron/routes')

Vue.use(VueRouter)

const routes = gyra_routes.routes
  .concat(iron_routes.routes)
  .filter((i) => {return i})

const router = new VueRouter({
  routes
})

import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import money from 'v-money'
Vue.use(money, {precision: 2})
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
