const axios = require('axios')
const {EventBus} = require('./event-bus.js')

class UploadFilesService {
    upload(file, onUploadProgress) {
      let formData = new FormData();
  
      formData.append("file", file);
  
      return axios.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    }
  
    getFiles() {
      return [];
    }
}
  
const uploadFilesService = new UploadFilesService()

const removeEmptyProps = (obj) => {
  return Object.keys(obj)
    .filter(function (k) {
      return obj[k] != null;
    })
    .reduce(function (arr, k) {
      arr[k] = typeof obj[k] === "object" ? removeEmptyProps(obj[k]) : obj[k]
      return arr
    }, {})
}

const getClient = (type, token, callback) => {
    const config = {type, token, callback}
    return axios.create(config)
}

const getBaseURL = () => {
    let baseURL
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'sandbox':
        baseURL = 'https://iron-sandbox.gyramais.com.br/api'
        break;
      case 'production':    
        baseURL = 'https://iron.gyramais.com.br/api'
        break;
      default:
        baseURL = 'http://localhost:5000/api'
        break;
    }
    return baseURL
}

const handleError = e => {
  const isProdEnv = process.env.VUE_APP_ENVIRONMENT === 'production'
  if (!isProdEnv) {
    console.log(e);
  }
  const message = e.response && e.response.data && e.response.data.message ? e.message + ' - ' + e.response.data.message : e.message
  EventBus.$emit('notification', {message: message, status: 'error'})
  return e.response ? e.response : e
}

const setCookie = function(cookieName,cookieValue,daysToExpire=1,path='/',domain=null) {
  const date = new Date();
  date.setTime(date.getTime()+(daysToExpire*24*60*60*1000));
  document.cookie = cookieName + "=" + cookieValue + "; expires=" + date.toGMTString() + 'path=' + path + 'domain=' + domain;
}

const getCookie = function (cookieName) {
  const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
  return cookieValue ? cookieValue.pop() : '';
}

const deleteCookie = function (cookieName) {
  document.cookie = cookieName + "=''; max-age=0; expires=0";
}

const lessThanXHours = (date, hours) => {
    const seconds = (new Date() - new Date(date)) / 1000
    const totalHours = Math.floor(seconds / 3600)
    if (hours > totalHours) {
        return true
    }
    return false
}

const formatMoney = item => {
    if (!item) {
        return (0).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
    }
    return isNaN(parseInt(item)) ?
        item :
        (item).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
}

const formatDate = (item, locale='pt-br') => {
    return new Date(item).toLocaleDateString(locale)
}

const formatDatetime = (item, locale='pt-br') => {
    return new Date(item).toLocaleDateString(locale) +
    'às' + new Date(item).toLocaleTimeString(locale)
}

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const timeSince = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = seconds / 31536000;
  
    if (interval > 1) {
      return 'há ' + Math.floor(interval) + " anos";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return 'há ' + Math.floor(interval) + " meses";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return 'há ' + Math.floor(interval) + " dias";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return 'há ' + Math.floor(interval) + " horas";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return 'há ' + Math.floor(interval) + " minutos";
    }
    return 'há ' + Math.floor(seconds) + " segundos";
}

const normalize = (s) => {
    if(!normalize.translate_re) normalize.translate_re = /[öäüÖÄÜ]/g;
    const translate = {
        "à": "a",
        "À": "A",
        "Ç": "C",
        "ç": "c",
        "ã": "a", "õ": "o",
        "Ã": "A", "Õ": "O",
        "ê": "e", "ô": "o",
        "Ê": "E", "Ô": "O",
        "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u",
        "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U",
    };
    return s.trim().replace(normalize.translate_re, (match) => { 
        return translate[match]; 
    });
}

const removeWhitespace = (s) => {
    const search = ' ';
    const searchRegExp = new RegExp(search, 'g'); // Throws SyntaxError
    const replaceWith = '-';
    return s.trim().replace(searchRegExp, replaceWith);
}

const capitalize = (s) => {
    s = s.trim()
    const condition = s.length < 3 ||( s[0] == 'd' && s.length === 3)
    return condition ? s : s[0].toUpperCase() + s.slice(1).toLowerCase()
}

const extractDigits = (s) => {
    if (typeof s !== 'string') {
        return s
    }
    return s.replace(/\D/g, "")
}

const lower = (s) => {
    return s.toLowerCase()
}

const name = (s) => {
    return capitalize(lower(s))
}

const slug = (s) => {
    return lower(removeWhitespace(normalize(s)))
}


const fromDictionary = (typing, slug) => {
    if (typing[slug] && typing[slug].name) {
        return typing[slug].name
    }
    return slug
}

const getMaxDate = (items, key) => {
    return new Date(Math.max(...items.map(e => new Date(e[key]))))
}

export {
    uploadFilesService,
    getClient,
    getBaseURL,
    handleError,
    setCookie,
    getCookie,
    deleteCookie,
    lessThanXHours,
    timeSince,
    normalize,
    removeWhitespace,
    capitalize,
    extractDigits,
    lower,
    name,
    slug,
    fromDictionary,
    formatBytes,
    formatDate,
    formatDatetime,
    formatMoney,
    removeEmptyProps,
    getMaxDate
}