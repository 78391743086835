<template>
  <v-app class="app">
    <v-app-bar
      app
      :color="$store.getters.palette.primary"
      dark
    >

      <v-app-bar-nav-icon v-if="$store.state.loggedIn" @click.stop="toggleMenu()"> 
      </v-app-bar-nav-icon>

      <v-progress-linear
        :active="$store.state.loading"
        :indeterminate="$store.state.loading"
        absolute
        bottom
        :color="$store.getters.palette.secondary"
      ></v-progress-linear>

      <div
        id="randomMarker"
        class="d-flex align-center"
      >
        <v-img
          alt="Gyramais Logo"
          class="shrink mr-2"
          contain
          src="/static/imgs/Gyra_Icone_COR2.png"
          width="70"
          icon
        />

        <h4>IRON</h4>
      </div>
      
      <v-spacer></v-spacer>
<!-- 
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="startTourButton"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="default"
                    v-bind="attrs"
                    v-on="on"
                    @click="startTour()"
                  >
                    <v-icon dark>
                      mdi-help
                    </v-icon>
                  </v-btn>
              </template>
              <span>Ajuda</span>
            </v-tooltip> -->

    </v-app-bar>
    <v-main>
      <v-alert
        v-for="notification in notifications"
        v-bind:key="notification.message"
        border="right"
        dark
        dismissible
        :color="notification.status"
      >{{notification.message}}

        <span v-if="notification.status === 'error'">

        <v-btn
          id="reportBugButton"
          class="mx-2"
          dark
          small
          @click="reportBug()"
        >
          <v-icon dark>
            mdi-bug
          </v-icon>
          Reportar Bug?
        </v-btn>

        </span>

      </v-alert>

      <login v-if="!$store.state.loggedIn"></login>
      <navigation-menu v-if="$store.state.loggedIn" :roles="roles"></navigation-menu>
      <router-view v-if="$store.state.loggedIn"></router-view>
    </v-main>
    
  </v-app>
</template>

<script>
import Login from './gyra/screens/Login.vue'
import NavigationMenu from './gyra/components/NavigationMenu.vue'
const {getCookie, deleteCookie} = require('./addons/helpers.js')
const {EventBus} = require('./addons/event-bus.js')

export default {
  name: 'App',
  components: {
    Login,
    NavigationMenu
  },
  data() {
    return {
      notifications: [],
      steps: [],
    }
  },
  computed: {
    roles() {
      return this.$store.getters.profileRoles.map(i => {return i.value ? i.value : false})
    }
  },
  methods: {
    reportBug() {
      this.$store.commit('setBugReportLink', this.$route.path)
      this.$router.push({path: '/bug/add'})
    },
    toggleMenu() {
      EventBus.$emit('drawer')
    }
  },
  watch:{
    $route (to, from) {
      if (to.name != from.name) {
        this.notifications = []
      }
      window.scrollTo(0,0)
    }
  },
  mounted() {


    this.notifications = []
    const access_cookie = getCookie('access_token');
    const refresh_cookie = getCookie('refresh_token');
    const logout = () => {
      let action = 'logout'
      if (this.roles.includes('lawyer')) {
        action = 'legalLogout'
      }
      const p1 = this.$store.dispatch(action)
      return p1.then(r => {
        this.$store.commit('clearState')
        this.$store.commit('setReady')
        deleteCookie('access_token')
        deleteCookie('refresh_token')
        this.$router.go()
        return r
      })
    }
    const showNotification = n => {
      this.notifications.push(n)
      window.scrollTo(0,0)
    }
    const load = () => {
      const p1 = this.$store.dispatch('getAppDefinition')
      p1.then(r => {
        if (r.status === 401) {
          EventBus.$emit('logout')
          this.$store.commit('refresh')
        }
        this.$store.commit('setReady')
        return r
      })
    }

    this.$store.commit('initClient')
    EventBus.$on('notification', showNotification)

    if (access_cookie &&
      refresh_cookie &&
      access_cookie.length == 32 &&
      refresh_cookie.length === 32) {

      const credentials = {
        access_token: access_cookie,
        refresh_token: refresh_cookie
      }
      
      this.$store.commit('credentials', credentials)
      this.$store.commit('resetClient') // axios with auth header
      EventBus.$on('logout', logout)
      load()
    }
  },
}
</script>

<style>
a {
  text-decoration: none;
}
</style>

