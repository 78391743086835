
const routes = [
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */'./screens/Test.vue')
  },
  {
    path: '/type/:scope/add',
    name: 'AddEnum',
    component: () => import(/* webpackChunkName: "enum" */'./screens/AddEnum.vue')
  },
  {
    path: '/profile',
    name: 'ViewProfile',
    component: () => import(/* webpackChunkName: "profile" */'./screens/ViewProfile.vue')
  },
  {
    path: '/profile/edit',
    name: 'EditProfile',
    component: () => import(/* webpackChunkName: "profile" */'./screens/EditProfile.vue')
  },
  {
    path: '/profile/password/reset',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "profile" */'./screens/ResetPassword.vue')
  },
  
  // PRECISAM FICAR POR ULTIMO
  {
    path: '/user/:action?/:id?',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */'./screens/User.vue')
  },
  {
    path: '/role/:action?/:id?',
    name: 'Role',
    component: () => import(/* webpackChunkName: "role" */'./screens/Role.vue')
  },
  {
    path: '/type/:action?/:id?',
    name: 'Type',
    component: () => import(/* webpackChunkName: "type" */'./screens/Type.vue')
  },
  {
    path: '/bug-report/:action?/:id?',
    name: 'BugReport',
    component: () => import(/* webpackChunkName: "bug" */'./screens/BugReport.vue')
  },
]

export {
  routes
}
