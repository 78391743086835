<template>
  <v-container>
    <v-row>
      <v-col md=6>
        <v-card class="px-4">
            <v-card-text>
                <v-form ref="loginForm" v-model="valid" lazy-validation>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="loginEmail" :rules="loginEmailRules" label="E-mail" required></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="loginPassword" :append-icon="show1?'eye':'eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="6" xsm="12">
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                            <v-btn x-large block :disabled="!valid" :color="$store.getters.palette.primary" @click="validate"> Login </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    export default {
      name: 'LoginForm',
      methods: {
        validate() {
            let action = 'login'
            const isInternal = this.loginEmail.includes('gyramais.com') || this.loginEmail.includes('@iron.com') 
            if (!isInternal) {
              action = 'legalLogin'
            }
            const p1 = this.$store.dispatch(action, {email: this.loginEmail, password: this.loginPassword})
            return p1.then(r => {
              this.$store.commit('setReady')
              r.status == 200 ? this.$router.go() : null
            })
        },
        reset() {
          this.$refs.form.reset()
        },
        resetValidation() {
          this.$refs.form.resetValidation();
        }
      },
      data: () => ({
        valid: false,
        dialog: true,
        email: "",
        password: "",
        loginPassword: "",
        loginEmail: "",
        loginEmailRules: [
          v => !!v || "Required",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],

        show1: false,
        rules: {
          required: v => !!v || "Required.",
          min: v => (v && v.length >= 4) || "Min 4 characters"
        }
      })


    }
</script>