
const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */'./screens/Dashboard.vue')
  },
  {
    path: '/lawsuit/import',
    name: 'LawsuitImport',
    component: () => import(/* webpackChunkName: "lawsuit" */'./screens/LawsuitImport.vue')
  },
  {
    path: '/lawsuit/file/:id/:file/add',
    name: 'AddLawsuitFile',
    component: () => import(/* webpackChunkName: "lawsuitfile" */'./screens/AddLawsuitFile.vue')
  },
  {
    path: '/lawsuit/file/:id/cost/:refcode/:file/add',
    name: 'AddCostFile',
    component: () => import(/* webpackChunkName: "costfile" */'./screens/AddCostFile.vue')
  },
  
  // PRECISA FICAR POR ULTIMO
  {
    path: '/office/:action?/:id?',
    name: 'Office',
    component: () => import(/* webpackChunkName: "office" */'./screens/Office.vue')
  },
  {
    path: '/lawsuit/:action?/:id?/:tab?/:tabAction?/:refcode?',
    name: 'Lawsuit',
    component: () => import(/* webpackChunkName: "lawsuit" */'./screens/Lawsuit.vue')
  },
]

export {
  routes
}
