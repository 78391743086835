import Vue from 'vue'

class GyraEventBus extends Vue {
   constructor() {
    super()
    this.emitMiddleware = []
    this.onMiddleware = []
  }
   
  $register(action, callback) {
    if (callback && typeof callback.call === 'function') {
        if (action == 'on') {
          this.onMiddleware.push(callback)
          return this.onMiddleware.length - 1
        }
        if (action == 'emit') {
          this.emitMiddleware.push(callback)
          return this.emitMiddleware.length - 1
        }
    }
  }

  $emit(event, ...args) {
    if (this.emitMiddleware.length > 0) {
        this.emitMiddleware.map(e => e(event, ...args))
    }
    return super.$emit(event, ...args)
  }

  $on(event, ...args) {
    if (this.onMiddleware.length > 0) {
        this.onMiddleware.map(e => e(event, ...args))
    }
    return super.$on(event, ...args)
  }

  $off(event) {
    return super.$off(event)
  }
}

const EventBus = new GyraEventBus()

export {
  EventBus
}
