
<template>
<v-navigation-drawer :clipped="false" v-model="sidebarMenu" enable-resize-watcher app temporary>    
        <v-list-item class="px-2">
            <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="text-truncate">
                <a href="#/profile">
                {{$store.getters.profile.name}}
                </a>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list>
            <v-list-item v-for="item in items" :key="item.title" link :to="item.href">
                <v-list-item-icon>
                    <v-icon :color="$store.getters.palette.secondary">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item class="px-2">
                <v-list-item-content>
                    <v-btn class="primary--text" @click="logout()">Sair</v-btn>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
  const {EventBus} = require('../../addons/event-bus.js')


export default {
  name: 'NavigationMenu',
  props: ['roles'],
  data: () => ({
      sidebarMenu: false,
      items: [],
  }),
  methods: {
      logout() {
          EventBus.$emit('logout')
      }
  },
  mounted() {
    const toggleDrawer = () => this.sidebarMenu = !this.sidebarMenu
    EventBus.$on('drawer', toggleDrawer)

    const loadMenu = () => {
        if (this.items.length > 0) {
            return
        }
        this.items.push({ title:"Home", href:"/", icon:"mdi-home-outline" })
        
        const rolesVals = this.$store.getters.profileRoles.map((i) => {return i.value})
        if (rolesVals.includes('admin') || rolesVals.includes('debt-collection') || rolesVals.includes('finance') || rolesVals.includes('lawyer')) {
            this.items.push({ title:"Casos", href:"/lawsuit", icon:"mdi-file" })
        }
        if (rolesVals.includes('admin') || rolesVals.includes('debt-collection') || rolesVals.includes('finance') ) {
            this.items.push({ title:"Escritórios", href:"/office", icon:"mdi-briefcase" })
        }
        if (rolesVals.includes('admin')) {
            this.items.push({ title:"Configurações", href:"/type", icon:"mdi-cog" })
            this.items.push({ title:"Usuários", href:"/user", icon:"mdi-account" })
            this.items.push({ title:"Papéis de Usuário", href:"/role", icon:"mdi-account-multiple" })
            this.items.push({ title:"Bugs", href:"/bug-report", icon:"mdi-bug" })
        }
    }
    EventBus.$on('definition-loaded', loadMenu)
  },
  beforeDestroy() {
    EventBus.$off('definition-loaded')
  }
}
</script>

<style>
</style>
