import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import FileDownload from 'js-file-download';
const {EventBus} = require('./event-bus.js')
const {setCookie, deleteCookie} = require('./helpers.js')

Vue.use(Vuex)

let client, baseURL

switch (process.env.VUE_APP_ENVIRONMENT) {
  case 'sandbox':
    baseURL = 'https://iron-sandbox.gyramais.com.br/api'
    break;
  case 'production':    
    baseURL = 'https://iron.gyramais.com.br/api'
    break;
  default:
    baseURL = 'http://localhost:5000/api'
    break;
}

const fixPrefix = (prefix) => {
  return prefix === 'type' ? 'typing' : prefix
}

const handleError = e => {
  console.log(e)
  let message = e.response && e.response.data && e.response.data.message ? e.message + ' - ' + e.response.data.message : e.message
  EventBus.$emit('notification', {message: message, status: 'error'})
  return e.response ? e.response : e
}

const initialState = {
  definition: {
    dictionary: {},
    identity: {
      roles: [{value: 'public', name: 'Public'}],
      email: '',
      name: '',
      first_name: '',
      last_name: '',
      phone: '',
    },
    env: 'development',
    app: 'iron',
    security: {
      access_token: null,
      refresh_token: null
    },
  },
  entities: {
    activity: [],
    loanAnalysis: {
      item: {},
      items: [],
      filters: {
        status: '',
        createdSince: '',
        createdUntil: '',
        modifiedSince: '',
        modifiedUntil: '',
      }
    },
    partner: {
      isCollectionEmpty: false,
      item: {},
      items: [],
    },
    user: {
      isCollectionEmpty: false,
      item: {},
      items: [],
      profile: {},
      roles: [],
    },
    typing: {
      isCollectionEmpty: false,
      items: [],
      item: {},
      dictionary: {}
    },
    role: {
      isCollectionEmpty: false,
      item: {},
      items: [],
    },
    bug: {
      isCollectionEmpty: false,
      item: {},
      items: [],
      link: ''
    },
    office: {
      isCollectionEmpty: false,
      item: {},
      items: [],
    },
    lawsuit: {
      isCollectionEmpty: false,
      item: {},
      items: [],
      reports: {
        downloadLink: '',
      },
      filters: {
        client_number: '',
        office: '',
        case: '',
        portfolio: '',
        status: '',
        createdSince: '',
        createdUntil: '',
        modifiedSince: '',
        modifiedUntil: '',
      }
    },
  },
  file: {
    progress: 0
  },
  loggedIn: false,
  loading: false,
  requesting: false,
}

const store = new Vuex.Store({

  state: initialState,

  getters: {
    palette (state) {
      const colors = state.entities.typing.items.filter(i => {return i.scope === 'palette'})
      const palette = {
        primary: '',
        secondary: ''
      }
      for (let index = 0; index < colors.length; index++) {
        if (colors[index].value === 'primary-color') {
          palette.primary = colors[index].color
        }
        if (colors[index].value === 'secondary-color') {
          palette.secondary = colors[index].color
        }
      }
      return palette
    },
    activity (state) {
      return state.entities.activity
    },
    profile (state) {
      return state.definition.identity
    },
    profileRoles (state) {
      return state.definition.identity.roles
    },
    dictionary (state) {
      return state.entities.typing.dictionary
    },
    typingList (state) {
      return state.entities.typing.items
    },
    findOne: (state) => (name, id) => {
      const objIndex = state.entities[name].items.findIndex(obj=> obj.id === id)
      if (state.entities[name].items[objIndex]) {
        state.entities[name].item = state.entities[name].items[objIndex] 
      }
      return state.entities[name].item
    },
    findAll: (state) => (name) => {
      if (name) {
        return state.entities[name].items
      }
      return [] 
    },
    typing: (state) => (scope) => {
      return state.entities.typing.items.filter(i => {return i.scope === scope})
    },
    currentType (state) {
      return state.entities.typing.item
    },
    typingSelectList (state) {
      return state.entities.typing.items.map(i => {
        return {
          scope: i.scope,
          value: i.value,
          name: i.name,
        }
      })
    },
    currentUser (state) {
      return state.entities.user.item
    },
    userList (state) {
      return state.entities.user.items
    },
    currentRole (state) {
      return state.entities.role.item
    },
    roleList (state) {
      return state.entities.role.items
    },
    bugList (state) {
      return state.entities.bug.items
    },
    currentBug (state) {
      return state.entities.bug.item
    },
    currentPartner (state) {
      return state.entities.partner.item
    },
    partnerList (state) {
      return state.entities.partner.items
    },
    currentLoanAnalysis (state) {
      return state.entities.loanAnalysis.item
    },
    loanAnalysisList (state) {
      return state.entities.loanAnalysis.items
    },
    officeSelectList (state) {
      if (!state.entities.office.items) {
        return
      }
      return state.entities.office.items.map(i => {
        return {
          id: i.id,
          name: i.name
        }
      })
    },
    currentLawsuit (state) {
      return state.entities.lawsuit.item
    },
    lawsuitList (state) {
      return state.entities.lawsuit.items
    },
    lawsuitStatus (state) {
      return state.entities.typing.items.filter(i => i.scope === 'lawsuit-status')
    },
    portfolios (state) {
      return state.entities.typing.items.filter(i => i.scope === 'portfolio')
    },
    provinces (state) {
      return state.entities.typing.items.filter(i => i.scope === 'province')
    },
    costTypes (state) {
      return state.entities.typing.items.filter(i => i.scope === 'cost-type' || i.scope === 'custom-cost-type')
    },
  },

  mutations: {
    setLoading (state) {
      state.loading = true
    },
    setReady (state) {
      state.loading = false
    },

    loadAppDefinition (state, data) {
      for (let i = data.typing.length - 1; i >= 0; i--) {
        state.entities.typing.dictionary[data.typing[i].value] = data.typing[i]
      }
      state.entities.typing.items = data.typing
      state.entities.role.items = data.roles
      state.definition.identity.name = data.user.first_name + ' ' + data.user.last_name
      state.definition.identity.first_name = data.user.first_name
      state.definition.identity.last_name = data.user.last_name
      state.definition.identity.email = data.user.email
      state.definition.identity.phone = data.user.phone
      state.definition.identity.roles = data.user.roles
      state.definition.env = data.env
    },

    loadEntities (state, {prefix, data}) {
      if (!data) {
        return
      }
      prefix = fixPrefix(prefix)
      if (data && data.length === 0) {
        state.entities[prefix].isCollectionEmpty = true;  
      }
      if (!state.entities || !state.entities[prefix]) {
        state.entities[prefix] = {items: []}
      }
      state.entities[prefix].items = data
    },

    loadEntity (state, {prefix, data}) {
      if (!data) {
        return
      }
      prefix = fixPrefix(prefix)
      const objIndex = state.entities[prefix].items.findIndex(obj=> obj.id === data.id)
      state.entities[prefix].items[objIndex] = data
      state.entities[prefix].item = data
    },

    removeEntity (state, {prefix, pk}) {
      prefix = fixPrefix(prefix)
      state.entities[prefix].items = state.entities[prefix].items.filter(i => {return i.id != pk})
    },

    loadActivity (state, data) {
      state.entities.activity = data
    },

    resetLoanAnalysisFilters (state) {
      state.entities.loanAnalysis.filters = {
        portfolio: '',
        status: '',
        createdUntil: '',
        createdSince: '',
        modifiedSince: '',
        modifiedUntil: '',
      }
    },

    loadStatus (state, data) {
      state.entities.lawsuit.item.status = data
    },

    resetLawsuitFilters (state) {
      state.entities.lawsuit.filters = {
        portfolio: '',
        status: '',
        createdUntil: '',
        createdSince: '',
        modifiedSince: '',
        modifiedUntil: '',
      }
    },

    removeCost (state, pk, refcode) {
      const objIndex = state.entities.lawsuit.items.findIndex(obj=> obj.id === pk)
      const lawsuit = state.entities.lawsuit.items[objIndex]
      lawsuit.costs = lawsuit.costs.filter(i => {return i.refcode != refcode})
      state.entities.lawsuit.items[objIndex] = lawsuit
    },

    removedProperty (state, pk, refcode) {
      const oldItems = state.entities.lawsuit.items
      const newItems = [] 
      for (let index = 0; index < oldItems.length; index++) {
        if (oldItems[index].id != pk) {
          const newProperties = []
          const seized_properties = oldItems[index].seized_properties
          for (let ii = 0; ii < seized_properties.length; ii++) {
            const seized_property = seized_properties[ii]
            if (seized_property.refcode !== refcode) {
              newProperties.push(seized_property)
            }
          }
          oldItems[index].seized_properties = newProperties
        }
        newItems.push(oldItems[index])
      }
      state.entities.lawsuit.items = newItems
    },

    credentials (state, data) {
      if (data.access_token &&
          data.refresh_token &&
          data.access_token.length == 32 &&
          data.refresh_token.length === 32) {
          state.definition.security.access_token = data.access_token
          state.definition.security.refresh_token = data.refresh_token  
          state.loggedIn = true;
        }
    },

    setRefreshClient (state) {
      const config = {
        baseURL: baseURL,
        headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + state.definition.security.refresh_token
        }
      }
      client = axios.create(config)
    },

    initClient () {
      const config = {
        baseURL: baseURL,
      }
      client = axios.create(config)

      // client.interceptors.request.use(request => {
      //   console.log('Starting Request', JSON.stringify(request, null, 2))
      //   return request
      // })
      
      // client.interceptors.response.use(response => {
      //   console.log('Response:', JSON.stringify(response, null, 2))
      //   return response
      // })
    },

    resetClient (state) {
      const config = {
        baseURL: baseURL,
        headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + state.definition.security.access_token
        }
      }
      client = axios.create(config)
    },

    refreshClient (state) {
      const config = {
        baseURL: baseURL,
        headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + state.definition.security.refresh_token
        }
      }
      client = axios.create(config)
    },

    uploadClient (state) {
      const onUploadProgress = event => {
        state.file.progress = Math.round((100 * event.loaded) / event.total);
      }
      const config = {
        baseURL: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer ' + state.definition.security.access_token
        },
        onUploadProgress
      }
      client = axios.create(config)
    },

    loadProfile (state, profile) {
      state.entities.user.profile = profile
    },

    setBugReportLink(state, link) {
      state.entities.bug.link = link
    },

    clearBugReportLink(state) {
      state.entities.bug.link = ''
    },

    downloadReport(state, filename) {
      state.entities.lawsuit.reports.downloadLink = filename
    },

    clearState (state) {
      for (const key in initialState) {
        if (Object.hasOwnProperty.call(initialState, key)) {
          state[key] = initialState[key]
        }
      }
    },

    selectEntity(state, {prefix, pk}) {
      console.log(prefix)
      let item = {}
      prefix = fixPrefix(prefix)
      for (let index = 0; index < state.entities[prefix].items.length; index++) {
        if (state.entities[prefix].items[index].id === pk) {
          item = state.entities[prefix].items[index]
        }
      }
      state.entities[prefix].item = item
    }, 

    pushEntity(state, {prefix, pk, item}) {
      prefix = fixPrefix(prefix)
      for (let index = 0; index < state.entities[prefix].items.length; index++) {
        if (state.entities[prefix].items[index].id === pk) {
          state.entities[prefix].items[index] = item
        }
      }
      state.entities[prefix].items = state.entities[prefix].items.filter()
    }, 

    selectEntityAttachment(state, {prefix, pk}) {
      let item = {}
      prefix = fixPrefix(prefix)
      for (let index = 0; index < state.entities[prefix].items.length; index++) {
        if (state.entities[prefix].items[index].id === pk) {
          item = state.entities[prefix].items[index]
        }
      }
      state.entities[prefix].item = item
    }, 

    pushEntityAttachment(state, {prefix, pk, item}) {
      prefix = fixPrefix(prefix)
      for (let index = 0; index < state.entities[prefix].items.length; index++) {
        if (state.entities[prefix].items[index].id === pk) {
          state.entities[prefix].items[index] = item
        }
      }
    }, 
  },

  actions: {
    login ({commit}, credentials) {
      commit('setLoading')
      console.log('login', credentials)
      return client.post('/login', credentials).then(r => {
        if (r.data.access_token && r.data.refresh_token) {
          commit('credentials', r.data)
          setCookie('access_token', r.data.access_token)
          setCookie('refresh_token', r.data.refresh_token)
          commit('resetClient')
          EventBus.$emit('logged-in', r.data)
        }
        return r
      }).catch(e => {
        commit('initClient');
        return handleError(e)
      });
    },
    
    legalLogin ({commit}, credentials) {
      commit('setLoading')
      return client.post('/legal/login', credentials).then(r => {
        if (r.data.access_token && r.data.refresh_token) {
          commit('credentials', r.data)
          setCookie('access_token', r.data.access_token)
          setCookie('refresh_token', r.data.refresh_token)
          commit('resetClient')
          EventBus.$emit('logged-in', r.data)
        }
        return r
      }).catch(e => {
        commit('initClient');
        return handleError(e)
      });
    },

    refresh ([commit]) {
      return client.post('/refresh').then(r => {
        if (r.data.access_token && r.data.refresh_token) {
          commit('credentials', r.data)
          setCookie('access_token', r.data.access_token)
          setCookie('refresh_token', r.data.refresh_token)
          commit('resetClient')
        }
        return r
      }).catch(e => {
        commit('initClient');
        return handleError(e)
      });
    },

    legalRefresh ([commit]) {
      return client.post('/legal/refresh').then(r => {
        if (r.data.access_token && r.data.refresh_token) {
          commit('credentials', r.data)
          setCookie('access_token', r.data.access_token)
          setCookie('refresh_token', r.data.refresh_token)
          commit('resetClient')
        }
        return r
      }).catch(e => {
        commit('initClient');
        return handleError(e)
      });
    },

    logout ({commit}) {
      commit('setLoading')
      commit('setRefreshClient');
      return client.post('/legal/logout').then(r => {
        commit('clearState');
        deleteCookie('access_token')
        deleteCookie('refresh_token')
        commit('initClient');
        EventBus.$emit('logged-out', r.data)
        return r
      }).catch(e => {
        commit('resetClient')
        return handleError(e)
      });
    },
    
    legalLogout ({commit}) {
      commit('setLoading')
      commit('setRefreshClient');
      return client.post('logout').then(r => {
        commit('clearState');
        deleteCookie('access_token')
        deleteCookie('refresh_token')
        commit('initClient');
        EventBus.$emit('logged-out', r.data)
        return r
      }).catch(e => {
        commit('resetClient')
        return handleError(e)
      });
    },

    // GENERICS
    getEntities ({commit}, {prefix}) {
      commit('setLoading')
      prefix = fixPrefix(prefix)
      return client.get(prefix).then(r => {
        commit('loadEntities', {prefix, data: r.data})
        return r
      }).catch(handleError)
    },

    getEntity ({commit}, {prefix, pk}) {
      commit('setLoading')
      prefix = fixPrefix(prefix)
      return client.get('/' + prefix + '/' + pk).then(r => {
        commit('loadEntity', {prefix, data: r.data})
        return r
      }).catch(handleError)
    },

    updateEntity ({commit}, {prefix, item, pk}) {
      commit('setLoading')
      prefix = fixPrefix(prefix)
      console.log('prefix', prefix)
      return client.put('/'+ prefix + '/' + pk, item).then(r => {
        EventBus.$emit('notification', {message: 'atualizado com sucesso', status: 'success'})
        EventBus.$emit(prefix + '-updated')
        return r
      }).catch(handleError)
    },

    addEntity ({commit}, {prefix, item}) {
      commit('setLoading')
      console.log(prefix);
      prefix = fixPrefix(prefix)
      return client.post(prefix, item).then(r => {
        EventBus.$emit('notification', {message: 'criado com sucesso', status: 'success'})
        EventBus.$emit(prefix + '-created')
        return r
      }).catch(handleError)
    },

    removeEntity ({commit}, {prefix, pk}) {
      commit('setLoading')
      prefix = fixPrefix(prefix)
      return client.delete('/' + prefix + '/' + pk).then(r => {
        commit('removeEntity', {prefix, pk})
        EventBus.$emit('notification', {message: 'removido com sucesso', status: 'success'})
        EventBus.$emit(prefix + '-removed')
        return r
      }).catch(handleError)
    },

    // SPECIFICS    
    getInternalLawsuit ({commit}, {pk}) {
      commit('setLoading')
      return client.get('/lawsuit/' + pk).then((r) => {
        commit('loadEntity', {prefix: 'lawsuit', data: r.data})
        return r
      }).catch(handleError)
    },

    addInternalLawsuit ({commit}, {item}) {
      commit('setLoading')
      return client.post('/lawsuit', item).then(r => {
        EventBus.$emit('notification', {message: 'Caso criado', status: 'success'})
        EventBus.$emit('lawsuit-created')
        return r
      }).catch(handleError)
    },

    updateInternalLawsuit ({commit}, {item, pk}) {
      commit('setLoading')
      return client.put('/lawsuit/' + pk, item).then(r => {
        EventBus.$emit('notification', {message: 'Caso salvo', status: 'success'})
        EventBus.$emit('lawsuit-updated')
        return r
      }).catch(handleError)
    },

    removeInternalLawsuit ({commit}, {pk}) {
      commit('setLoading')
      return client.delete('/lawsuit/' + pk).then(r => {
        commit('removeEntity', {prefix: 'lawsuit', pk})
        EventBus.$emit('notification', {message: 'Caso removido', status: 'success'})
        EventBus.$emit('lawsuit-removed')
        return r
      }).catch(handleError)
    },
    
    getAppDefinition({commit}) {
      commit('setLoading')
      return client.get().then(r => {
        commit('loadAppDefinition', r.data)
        EventBus.$emit('definition-loaded')
        return r
      }).catch(handleError)
    },

    getProfile({commit}) {
      commit('setLoading')
      return client.get('/me').then(r => {
        commit('loadProfile', r.data)
        return r
      }).catch(handleError)
    },

    getLegalActivity({commit}) {
      commit('setLoading')
      return client.get('/legal/activity').then(r => {
        commit('loadActivity', r.data)
        return r
      }).catch(handleError)
    },

    getActivity({commit}) {
      commit('setLoading')
      return client.get('/activity').then(r => {
        commit('loadActivity', r.data)
        return r
      }).catch(handleError)
    },

    updateProfile ({commit}, item) {
      commit('setLoading')
      return client.put('/me', item).then(r => {
        EventBus.$emit('notification', {message: 'Perfil salvo', status: 'success'})
        EventBus.$emit('profile-updated')
        return r
      }).catch(handleError)
    },
    getLegalUsers ({commit}) {
      commit('setLoading')
      return client.get('/legal/user').then(r => {
        commit('loadUsers', r.data)
        return r
      }).catch(handleError)
    },
    
    getLegalUser ({commit}, {pk}) {
      commit('setLoading')
      return client.get('/legal/user/' + pk).then(r => {
        commit('loadUser', r.data)
        return r
      }).catch(handleError)
    },

    addLegalUser ({commit}, {item}) {
      commit('setLoading')
      return client.post('/legal/user', item).then(r => {
        EventBus.$emit('notification', {message: 'Usuário criado', status: 'success'})
        EventBus.$emit('user-created')
        return r
      }).catch(handleError)
    },

    updateLegalUser ({commit}, {item, pk}) {
      commit('setLoading')
      return client.put('/legal/user/' + pk, item).then(r => {
        EventBus.$emit('notification', {message: 'Usuário salvo', status: 'success'})
        EventBus.$emit('user-updated')
        return r
      }).catch(handleError)
    },

    removeLegalUser ({commit}, {pk}) {
      commit('setLoading')
      return client.delete('/legal/user/' + pk).then(r => {
        commit('removeEntity', 'user', pk)
        EventBus.$emit('notification', {message: 'Usuário removido', status: 'success'})
        EventBus.$emit('user-removed')
        return r
      }).catch(handleError)
    },

    getLegalLawsuits ({commit}, filters) {
      commit('setLoading')
      return client.get('/legal/lawsuit', {params: {filters}}).then(r => {
        commit('loadEntities', {prefix: 'lawsuit', data: r.data})
        return r
      }).catch(handleError)
    },

    getLegalLawsuit ({commit}, {pk}) {
      commit('setLoading')
      return client.get('/legal/lawsuit/' + pk).then(r => {
        commit('loadEntity', {prefix: 'lawsuit', data: r.data})
        return r
      }).catch(handleError)
    },

    updateLegalLawsuit ({commit}, {item, pk}) {
      commit('setLoading')
      return client.put('/legal/lawsuit/' + pk, item).then(r => {
        EventBus.$emit('notification', {message: 'Caso salvo', status: 'success'})
        EventBus.$emit('lawsuit-updated')
        return r
      }).catch(handleError)
    },

    getInternalLawsuits ({commit}, filters) {
      commit('setLoading')
      return client.get('/lawsuit', {params:{filters}}).then(r => {
        commit('loadEntities', {prefix: 'lawsuit', data: r.data})
        return r
      }).catch(handleError)
    },

    commentLawsuit ({commit}, {item, pk}) {
      commit('setLoading')
      return client.put('/legal/lawsuit/' + pk + '/comment', item).then(r => {
        EventBus.$emit('notification', {message: 'Observação adicionada', status: 'success'})
        EventBus.$emit('comment-added')
        return r
      }).catch(handleError)
    },

    addLegalLawsuitCost ({commit}, {item, pk}) {
      commit('setLoading')
      return client.post('/legal/lawsuit/' + pk + '/cost', item).then(r => {
        EventBus.$emit('notification', {message: 'Custa processual adicionada', status: 'success'})
        EventBus.$emit('cost-added')
        return r
      }).catch(handleError)
    },

    updateLegalLawsuitCost ({commit}, {item, pk, refcode}) {
      commit('setLoading')
      return client.put('/legal/lawsuit/' + pk + '/cost/' + refcode, item).then(r => {
        EventBus.$emit('notification', {message: 'Custa processual adicionada', status: 'success'})
        EventBus.$emit('cost-updated')
        return r
      }).catch(handleError)
    },

    addInternalLawsuitCost ({commit}, {item, pk}) {
      commit('setLoading')
      return client.post('/lawsuit/' + pk + '/cost', item).then(r => {
        EventBus.$emit('notification', {message: 'Custa processual adicionada', status: 'success'})
        EventBus.$emit('cost-added')
        return r
      }).catch(handleError)
    },

    updateInternalLawsuitCost ({commit}, {item, pk, refcode}) {
      commit('setLoading')
      return client.put('/lawsuit/' + pk + '/cost/' + refcode, item).then(r => {
        EventBus.$emit('notification', {message: 'Custa processual editada', status: 'success'})
        EventBus.$emit('cost-updated')
        return r
      }).catch(handleError)
    },

    removeInternalLawsuitCost ({commit}, {pk, refcode}) {
      commit('setLoading')
      return client.delete('/lawsuit/' + pk + '/cost/' + refcode).then(r => {
        EventBus.$emit('notification', {message: 'Custa processual removida', status: 'success'})
        EventBus.$emit('cost-removed')
        return r
      }).catch(handleError)
    },






    addLegalLawsuitSeizedProperty ({commit}, {item, pk}) {
      commit('setLoading')
      return client.post('/legal/lawsuit/' + pk + '/property', item).then(r => {
        EventBus.$emit('notification', {message: 'Bem adicionada', status: 'success'})
        EventBus.$emit('property-added')
        return r
      }).catch(handleError)
    },

    updateLegalLawsuitSeizedProperty ({commit}, {item, pk, refcode}) {
      commit('setLoading')
      return client.put('/legal/lawsuit/' + pk + '/property/' + refcode, item).then(r => {
        EventBus.$emit('notification', {message: 'Bem adicionada', status: 'success'})
        EventBus.$emit('property-updated')
        return r
      }).catch(handleError)
    },

    removeLegalLawsuitSeizedProperty ({commit}, {pk, refcode}) {
      commit('setLoading')
      return client.delete('/legal/lawsuit/' + pk + '/property/' + refcode).then(r => {
        EventBus.$emit('notification', {message: 'Bem removida', status: 'success'})
        EventBus.$emit('property-removed')
        return r
      }).catch(handleError)
    },

    addInternalLawsuitSeizedProperty ({commit}, {item, pk}) {
      commit('setLoading')
      return client.post('/lawsuit/' + pk + '/property', item).then(r => {
        EventBus.$emit('notification', {message: 'Bem adicionada', status: 'success'})
        EventBus.$emit('property-added')
        return r
      }).catch(handleError)
    },

    updateInternalLawsuitSeizedProperty ({commit}, {item, pk, refcode}) {
      commit('setLoading')
      return client.put('/lawsuit/' + pk + '/property/' + refcode, item).then(r => {
        EventBus.$emit('notification', {message: 'Bem editada', status: 'success'})
        EventBus.$emit('property-updated')
        return r
      }).catch(handleError)
    },

    removeInternalLawsuitSeizedProperty ({commit}, {pk, refcode}) {
      commit('setLoading')
      return client.delete('/lawsuit/' + pk + '/property/' + refcode).then(r => {
        EventBus.$emit('notification', {message: 'Bem removida', status: 'success'})
        EventBus.$emit('property-removed')
        return r
      }).catch(handleError)
    },


    uploadLawsuitFile ({commit}, {file, filetype, pk}) {
      let formData = new FormData()  
      formData.append("file", file)
      commit('setLoading')
      commit('uploadClient')
      return client.post('/lawsuit/' + pk + '/' + filetype, formData).then(r => {
        commit('resetClient')
        EventBus.$emit('notification', {message: 'Arquivo enviado', status: 'success'})
        EventBus.$emit('file-uploaded')
        return r
      }).catch(handleError)
    },
  
    uploadLawsuitImport ({commit}, {file, office}) {
      let formData = new FormData()  
      formData.append("file", file)
      commit('setLoading')
      commit('uploadClient')
      return client.post('/lawsuit/import/' + office, formData).then(r => {
        commit('resetClient')
        EventBus.$emit('notification', {message: 'Arquivo enviado', status: 'success'})
        EventBus.$emit('file-uploaded')
        return r
      }).catch(handleError)
    },
  
    uploadLawsuitCostFile({commit}, {file, pk, filetype, refcode}) {
      let formData = new FormData()  
      formData.append("file", file)
      commit('setLoading')
      commit('uploadClient')
      return client.post('/lawsuit/' + pk + '/' + filetype + '/' + refcode, formData).then(r => {
        commit('resetClient')
        EventBus.$emit('notification', {message: 'Arquivo enviado', status: 'success'})
        EventBus.$emit('file-uploaded')
        return r
      }).catch(handleError)
    },

    resetPassword({commit}, {password}) {
      commit('setLoading')
      return client.post('/password/reset', {password}).then(r => {
        EventBus.$emit('notification', {message: 'Senha redefinida', status: 'success'})
        EventBus.$emit('password-reset')
        return r
      }).catch(handleError)
    },

    toSpreadsheet ({commit}, {spreadsheet}) {
      commit('setLoading')
      return client.post('/spreadsheet/' + spreadsheet.format, spreadsheet.payload).then(r => {
        commit('downloadReport', r.data.filename)
        EventBus.$emit('file-downloaded')
        return r
      }).catch(handleError)
    },

    pushEntity ({commit}, {prefix, pk}) {
      commit('pushEntity', {prefix, pk})
    },

    request ({commit}, {client, method, route, payload, callback}) {
      const storeCallback = callback(commit)
      return client[method](route, payload)
        .then(storeCallback)
        .catch(handleError)
    },
  }
})

export default store
