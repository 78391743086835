<template>
<v-container fluid>
  <v-row>
    <v-col
      md=4
      sm=12
      xs=12
    >
    <v-card
        class="mx-auto"
      >
        <v-img
          alt=""
          class="shrink mr-2"
          contain
          :src="'/static/illustrations/' + image + '.svg'"
          width="100%"
          icon
        />
        <v-card-title>
            <h1 class='title'>Login</h1>
        </v-card-title>
    </v-card>
    </v-col>

    <v-col
    >
      <div v-if="!$store.state.loading">
        <login-form></login-form>
      </div>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
  import LoginForm from '../components/LoginForm.vue'

  export default {
    components: {
        LoginForm,
    },
    name: 'Login',
    data() {
      return {
        'image': 'Finance',
      }
    }
  }
</script>
